import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, Button } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram'; // Иконка самолёта (Telegram)
import TourList from './components/TourList';
import TourPage from './components/TourPage';
import Terms from './components/Terms';
import logo from './logo.png'; // Импорт логотипа (замените путь на правильный)

const App = () => {
  return (
    <Router>
      <AppBar position="static" sx={{ backgroundColor: '#ffffff' }}> {/* Изменили цвет панели */}
        <Toolbar>
          {/* Логотип слева, кликабельный для возврата на главную страницу */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexGrow: 1 }}>
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                style={{ height: '40px', cursor: 'pointer' }}
              />
            </Link>
          </Box>

          {/* Ссылка на страницу соглашения с оранжевым цветом */}
          <Button
            component={Link}
            to="/terms"
            sx={{ color: '#FF9800', marginRight: '20px' }} // Изменили цвет текста
          >
            Соглашение
          </Button>

          {/* Иконка самолётика Telegram справа */}
          <IconButton
            component="a"
            href="https://t.me/exaynaBot"
            target="_blank"
            rel="noopener noreferrer"
            edge="end"
            aria-label="telegram"
          >
            <TelegramIcon style={{ color: 'black' }} /> {/* Чёрный самолётик */}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path="/" element={<TourList />} />
        <Route path="/tours/:id" element={<TourPage />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Router>
  );
};

export default App;
