import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from "react-slick"; // Импортируем карусель
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Импортируем стили для анимации
import './TourList.css';

const TourList = () => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAllTours();
  }, []);

  const fetchAllTours = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tours`);
      const data = await response.json();
      setTours(data); 
    } catch (error) {
      console.error('Ошибка загрузки туров:', error);
    } finally {
      setLoading(false);
    }
  };

  // Настройки для карусели
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Container sx={{ marginTop: '30px', paddingBottom: '50px' }}>
      {tours.length === 0 && !loading && (
        <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center', marginTop: '20px' }}>
          На данный момент туров нет
        </Typography>
      )}

      {/* Сетка для карточек туров */}
      <Grid container spacing={4}>
        {tours.map((tour) => (
          <Grid item xs={12} sm={6} md={4} key={tour.turId}>
            <Card
              component={Link}
              to={`/tours/${tour.turId}`}
              sx={{
                width: '100%',
                height: { xs: '300px', sm: '450px' },
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                color: 'inherit',
              }}
            >
              <Slider {...sliderSettings}>
                {tour.photoGallery.map((photo, index) => (
                  <div key={index}>
                    <img
                      src={photo || 'https://via.placeholder.com/300'}
                      alt={`Фото тура ${tour.name}`}
                      style={{ width: '100%', height: '300px', objectFit: 'cover' }} // Используем cover
                    />
                  </div>
                ))}
              </Slider>

              <CardContent sx={{ minHeight: '150px' }}>
                <Typography gutterBottom variant="h5">
                  {tour.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ maxHeight: '40px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {tour.smallDescriptions}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Анимация загрузки */}
      {loading && (
        <div className="loading-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <div className="double-ring"></div>
        </div>
      )}
    </Container>
  );
};

export default TourList;
