import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button, Grid } from '@mui/material';
import { Link } from '@mui/material';
import parse from 'html-react-parser';
import TourGallery from './TourGallery';
import './Gallery.css';
import './LoadingAnimation.css'; // Импорт анимации загрузки

const formatTelegramText = (text) => {
  if (!text) return '';

  let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  formattedText = text.replace(/_(.*?)_/g, '<em>$1</em>');
  formattedText = text.replace(/•\s*(.+)/g, '<li>$1</li>');
  formattedText = text.replace(/-\s*(.+)/g, '<li>$1</li>');

  if (formattedText.includes('<li>')) {
    formattedText = `<ul>${formattedText}</ul>`;
  }

  formattedText = formattedText.replace(/\n/g, '<br />');

  return formattedText;
};

const TourPage = () => {
  const { id } = useParams();
  const [tour, setTour] = useState(null);

  useEffect(() => {
    const fetchTour = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tours/${id}`);
        const data = await response.json();
        setTour(data);
      } catch (error) {
        console.error('Ошибка загрузки данных тура:', error);
      }
    };
    fetchTour();
  }, [id]);

  if (!tour) {
    return (
      <div className="loading-container">
        <div className="double-ring"></div>
      </div>
    );
  }

  const images = tour.photoGallery || [];

  return (
    <Container sx={{ paddingTop: 4, paddingBottom: 4 }}>
      {/* Галерея */}
      <TourGallery images={images} />

      {/* Описание тура */}
      <div className="tour-description">
        <Typography variant="h4" gutterBottom>{tour.name}</Typography>
        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Стоимость: {tour.cost} ₽</Typography>

        <Typography variant="h6" color="text.secondary" gutterBottom>
          {tour.region}, {tour.cityStart}
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>Условия проживания:</Typography>
            <Typography variant="body1">{parse(formatTelegramText(tour.livingConditions))}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>Что включено в стоимость:</Typography>
            <Typography variant="body1">{parse(formatTelegramText(tour.inCost))}</Typography>
          </Grid>
        </Grid>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
          {parse(formatTelegramText(tour.importantInform))}
        </Typography>

        <Typography variant="body1" gutterBottom sx={{ mt: 4 }}>
          {parse(formatTelegramText(tour.bigDescriptions))}
        </Typography>
      </div>

      <Link href={`https://t.me/ExaynaBot?start=${tour.turId}`} target="_blank" rel="noopener">
        <Button
          variant="contained"
          color="primary"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            padding: '10px 20px',
            backgroundColor: '#FF9800',
            '&:hover': { backgroundColor: '#FF5722' },
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
          }}
        >
          Забронировать в телеграм
        </Button>
      </Link>
    </Container>
  );
};

export default TourPage;
